<template>
  <div class="wrapper m2">
    <Loading v-if="loading" />
    <div class="overflow" v-else>
      <div>
        <input
          @keyup="search"
          v-model="search_query"
          class="search_input"
          placeholder="Zoeken..."
        />
        <b-button v-b-modal.create class="m2">
          + Nieuwe werknemer toevoegen
        </b-button>
        <b-modal id="create" ref="create_modal">
          <template #modal-header>
            <h2>Voeg een werknemer toe</h2>
          </template>
          <form>
            <div class="employee_input--container">
              <label class="employee_input--label">Voornaam</label>
              <input v-model="first_name" required />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Tussenvoegsel</label>
              <input v-model="preposition" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Achternaam</label>
              <input v-model="last_name" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Functie</label>
              <input v-model="work_function" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Telefoon nummer</label>
              <input v-model="phone_number" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Interne nummer</label>
              <input v-model="intern" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">E-mail</label>
              <input v-model="email" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Foto</label>
              <input v-model="foto" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Startdatum:</label>
              <input type="date" v-model="start_date" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Foto permission</label>
              <input type="checkbox" name="test" id="foto_permission" />
            </div>
          </form>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="postWorker"
              >
                Voeg werknemer toe
              </b-button>
            </div>
          </template>
        </b-modal>
        <b-modal id="edit" ref="edit_model">
          <template #modal-header>
            <h2>Wijzig werknemer</h2>
          </template>
          <form>
            <div><input :value="this.edit_id" readonly hidden /></div>
            <div class="employee_input--container">
              <label class="employee_input--label">Voornaam</label>
              <input v-model="edit_first_name" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Tussenvoegsel</label>
              <input v-model="edit_preposition" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Achternaam</label>
              <input v-model="edit_last_name" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Functie</label>
              <input v-model="edit_work_function" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Telefoon nummer</label>
              <input v-model="edit_phone_number" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Interne nummer</label>
              <input v-model="edit_intern" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">E-mail</label>
              <input v-model="edit_email" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Startdatum:</label>
              <input type="date" v-model="edit_start_date" />
            </div>
            <div class="employee_input--container">
              <label class="employee_input--label">Foto permission</label>
              <input
                type="checkbox"
                name="test"
                id="foto_permission"
                :checked="this.checkedbox"
              />
            </div>
          </form>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="editEmployee(edit_id)"
              >
                Wijzig werknemer
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>

      <table class="table employee_table">
        <thead>
          <th>Naam</th>
          <th>Tussenvoegsel</th>
          <th>Achternaam</th>
          <th>Telefoonnummer</th>
          <th>Intern</th>
          <th>Email</th>
          <th>Functie</th>
          <th>Startdatum</th>
          <th>Aanpassen</th>
          <th>Verwijderen</th>
        </thead>
        <tbody>
          <tr v-for="(item, key) in employees" :key="item.id">
            <td>{{ item.voornaam }}</td>
            <td>{{ item.tussenvoegsel }}</td>
            <td>{{ item.achternaam }}</td>
            <td>{{ item.telefoonnummer }}</td>
            <td>{{ item.intern }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.functie }}</td>
            <td>{{ item.startdatum }}</td>
            <td>
              <button
                type="button"
                class="employee_edit"
                @click="getIdFromRow(item)"
                v-b-modal.edit
              >
                Edit
              </button>
            </td>
            <td>
              <button
                type="button"
                class="employee_delete"
                @click="deleteEmployee(item.id, key)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import hasIndex from "@/functions/hasIndex";

export default {
  components: { Loading },
  data: () => ({
    loading: true,
    employee: null,
    first_name: null,
    edit_first_name: null,
    preposition: null,
    edit_preposition: null,
    last_name: null,
    edit_last_name: null,
    work_function: null,
    edit_work_function: null,
    phone_number: null,
    edit_phone_number: null,
    intern: null,
    edit_intern: null,
    email: null,
    edit_email: null,
    start_date: null,
    edit_start_date: null,
    foto: null,
    edit_id: null,

    checkedbox: null,
    original_employees: null,
    search_query: null,
  }),
  created() {
    request("employee", "GET").then(({ employees, error }) => {
      if (error) this.error = error;
      this.employees = employees;
      this.original_employees = employees;
      this.loading = false;
    });
  },
  methods: {
    postWorker() {
      const data = {
        first_name: this.first_name,
        preposition: this.preposition,
        last_name: this.last_name,
        functie: this.work_function,
        telefoonnummer: this.phone_number,
        intern: this.intern,
        email: this.email,
        foto: this.foto,
        permission: document.getElementById("foto_permission").checked,
        startdatum: this.start_date
      };
      request("employee", "POST", data)
        .then(() => {
          this.$store.dispatch("addNotification", {
            message: "Medewerker toegevoegd",
            type: "success",
          });
          this.$refs["create_modal"].hide();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteEmployee(id, key) {
      if (confirm("Gebruiker verwijderen?")) {
        const data = { id };
        request("employee", "DELETE", data).then(() => {
          this.employees.splice(key, 1);
          this.$forceUpdate();
        });
      }
    },

    editEmployee() {
      const data = {
        id: this.edit_id,
        first_name: this.edit_first_name,
        preposition: this.edit_preposition,
        last_name: this.edit_last_name,
        functie: this.edit_work_function,
        telefoonnummer: this.edit_phone_number,
        intern: this.edit_intern,
        email: this.edit_email,
        permission: document.getElementById("foto_permission").checked,
        startdatum: this.edit_start_date,
        isactive: 1
      };
      request("employee", "PATCH", data).then(() => {
        this.$store.dispatch("addNotification", {
          message: "Medewerker aangepast",
          type: "success",
        });
        this.$refs["edit_model"].hide();
        this.$router.go();
      });
    },

    getIdFromRow(item) {
      this.edit_id = item.id;
      this.edit_first_name = item.voornaam;
      this.edit_preposition = item.tussenvoegsel;
      this.edit_last_name = item.achternaam;
      this.edit_work_function = item.functie;
      this.edit_phone_number = item.telefoonnummer;
      this.edit_intern = item.intern;
      this.edit_email = item.email;
      this.edit_start_date = item.startdatum,
      this.checkedbox = item.permission;
    },

    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.search_query == "") {
          this.employees = this.original_employees;
        }
        if (this.search_query !== "") {
          const filteredEmployees = this.original_employees.filter(
            (item) =>
              hasIndex(item.voornaam, this.search_query) ||
              hasIndex(item.achternaam, this.search_query)
          );
          this.employees = filteredEmployees;
        }
        this.$forceUpdate();
      }, 100);
    },
  },
};
</script>
